<template>
  <div>
    <v-header></v-header>
    <v-banner></v-banner>
    <v-content></v-content>
    <v-footer></v-footer>
  </div>
</template>

<script>

import Header from '@/components/header'
import Banner from "@/components/banner"
import Content from "@/components/content"
import Footer from "@/components/footer"

export default {
  name: 'App',
  components: {
    'v-header': Header,
    'v-banner': Banner,
    'v-content': Content,
    'v-footer': Footer
  }
}
</script>
