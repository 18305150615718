<template>
    <el-carousel height="650px" trigger="click" arrow ="never" indicator-position="inside" interval="3000">
      <el-carousel-item v-for="(item, index) in bannerList" :key="index" :style="`background: url(${item}) no-repeat center`">
      </el-carousel-item>
    </el-carousel>
</template>

<script>
export default {
  data () {
    return {
      bannerList: [require('@/assets/a.jpg'), require('@/assets/b.jpg'), require('@/assets/c.jpeg'), require('@/assets/d.jpeg')]
    }
  }
}
</script>
<style scoped>
@import url("//unpkg.com/element-plus/dist/index.css");
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>