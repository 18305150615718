<template>
<div>
    <br>
    <br>
    <br>
    <br>
    <br>
    <div style="text-align: center;">
    <video class="mov" autoplay="autoplay" muted="muted" src="@/assets/video.mp4" loop="loop" poster="@/assets/logo.png" x5-playsinline="" playsinline="" webkit-playsinline="">
    </video>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    </div>
</template>
