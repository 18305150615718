<template>
  <div class="footer">
    <div class="w">
      <div class="info fl">
        <div class="msg">
          <p>
            <br>
            <br>
            <br>
            地址：成都市高新区天府二街166号
            <br>
            电话：028-61103391 邮箱：yuanlian@ylgame.top
            <br>
            版权所有：©2021-2032 成都元链科技有限责任公司
            <br>
            <a target="_blank" class="beian" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002004312"><img src="@/assets/beian.png"/>&nbsp;川公网安备 51019002004312号</a>&nbsp;
            <a target="_blank" class="beian" href="https://beian.miit.gov.cn">蜀ICP备2021028722号</a>
          </p>
        </div>
      </div>
      <div class="fr">
        <img src="@/assets/qrcode.jpg" alt="" class="icon">
        <img src="@/assets/logo.png" alt="" class="icon">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {}
  }
}
</script>
<style lang="css" scoped>
.footer {
  background-color: #202020;
  height: 200px;
  color: #a8a8a8;
  font-size: 14px;
}
.beian a,a:link,a:visited,a:hover,a:active {
  text-decoration: none;
  color: #a8a8a8;
}
.icon {
  margin-top: 10px;
  margin-left: 20px;
  height: 180px;
}
</style>
