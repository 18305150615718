<template>
    <div class="header">
        <img class="logo" src="@/assets/logo.png" alt="元链科技">
        <div class="header_nav">新闻中心</div>
        <div class="header_nav">游戏资料</div>
        <div class="header_nav">社区互动</div>
        <div class="header_nav">开放合作</div>
        <div class="header_nav">活动中心</div>
        <div class="header_right">游戏下载</div>
    </div>
</template>

<script>
export default {
    name: 'Header'
}
</script>

<style lang="css" scoped>
.header {
    height: 56px;
    width: 100%;
    background: #000;
}
.logo {
    padding-left: 30px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 20px;
    height: 52px;
    float: left;
}
.header_nav {
    width: 100px;
    float: left;
    line-height: 56px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-family: Alibaba-PuHuiTi-Medium;
}
  
.header_nav:hover {
    color: #fb3;
    cursor: pointer;
  }

.header_right {
    background: #fb3;
    text-align: center;
    color: #333;
    font-size: 16px;
    line-height: 56px;
    width: 120px;
    height: 100%;
    float: right;
    cursor: pointer;
    font-family: Alibaba-PuHuiTi-Medium;
}
</style>
